import main from '@/views/main.vue';

/**
 * 限定meta为必填
 * - title表示标题
 * - hideInMenu表示是否在菜单上显示，true隐藏， false显示，可为空
 * - icon表示菜单图标，可为空
 * - access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 */
export default [{
    path: '/login',
    name: 'login',
    meta: {
        title: '登录',
        hideInMenu: true
    },
    component: () => import('@/views/login.vue')
}, {
	path: '/home',
	name: 'home',
	meta: {
		title: '首页',
		icon: 'md-home',
		hideInMenu: true
	},
	component: () => import('@/views/home.vue')
}, {
    path: '/',
    name: '_home',
    redirect: '/home',
    meta: {
        title: '首页',
        hideInMenu: true
    },
    component: main,
    children: [{
        path: '/personal',
        name: 'personal',
        meta: {
            title: '个人资料',
            hideInMenu: true
        },
        component: () => import('@/views/personal/person.vue')
    }, {
        path: '/changePass',
        name: 'changePass',
        meta: {
            title: '修改密码',
            hideInMenu: true
        },
        component: () => import('@/views/personal/modifyPass.vue')
    }]
}, {
	path: '/system',
	name: 'system',
	meta: {
		title: '系统管理',
		icon: 'md-folder'
	},
	component: main,
	children: [{
		path: '/',
		name: '_home2',
		meta: {
			title: '首页',
			icon: 'md-document',
			parent: 'system',
			access: [ 'admin' ]
		},
		redirect: '/home'
	}, {
		path: '/system/agent',
		name: 'agentManage',
		meta: {
			title: '代理商管理',
			icon: 'md-document',
			parent: 'system',
			access: [ 'admin', 'agent' ]
		},
		component: () => import('@/views/agent.vue')
	}, {
		path: '/system/customer',
		name: 'customerManage',
		meta: {
			title: '客户管理',
			icon: 'md-document',
			parent: 'system',
			access: [ 'admin', 'agent' ]
		},
		component: () => import('@/views/customer.vue')
	}, {
		path: '/system/user',
		name: 'userManage',
		meta: {
			title: '用户管理',
			icon: 'md-document',
			parent: 'system',
			access: [ 'admin', 'agent' ]
		},
		component: () => import('@/views/user.vue')
	}, {
		path: '/system/device',
		name: 'deviceManage',
		meta: {
			title: '设备管理',
			icon: 'md-document',
			parent: 'system',
			access: [ 'admin', 'agent' ]
		},
		component: () => import('@/views/device.vue')
	}, {
		path: '/system/cdevice',
		name: 'customerDeviceManage',
		meta: {
			title: '设备管理',
			icon: 'md-document',
			parent: 'system',
			access: [ 'customer' ]
		},
		component: () => import('@/views/customerDevice.vue')
	}, {
		path: '/system/reportData',
		name: 'reportDataManage',
		meta: {
			title: '日志记录',
			icon: 'md-document',
			parent: 'system'
		},
		component: () => import('@/views/reportData.vue')
	}, {
		path: '/system/analysis',
		name: 'analysisManage',
		meta: {
			title: '统计分析',
			icon: 'md-document',
			parent: 'system'
		},
		component: () => import('@/views/analysis.vue')
	}, {
		path: '/system/record',
		name: 'recordManage',
		meta: {
			title: '通信数据',
			icon: 'md-document',
			parent: 'system'
		},
		component: () => import('@/views/record.vue')
	}]
}, {
	path: '/401',
	name: 'error_401',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/401.vue')
}, {
	path: '/500',
	name: 'error_500',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/500.vue')
}, {
	path: '/:catchAll(.*)',
	name: 'error_404',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/404.vue')
}];