const title = '智能液体分配器管理平台';

/**
 * 根据当前跳转的路由设置显示在浏览器标签的title
 * @param {*} routeItem 路由对象
 * @param {*} vm  Vue实例
 */
export const setTitle = (routeItem, vm) => {
    const handledRoute = getRouteTitleHandled(routeItem)
    const pageTitle = showTitle(handledRoute, vm)
    const resTitle = pageTitle ? `${title} - ${pageTitle}` : title
    window.document.title = resTitle
}

export const getRouteTitleHandled = (route) => {
    let router = { ...route }
    let meta = { ...route.meta }
    let title = ''
    if (meta.title) {
        if (typeof meta.title === 'function') {
            meta.__titleIsFunction__ = true
            title = meta.title(router)
        } else 
            title = meta.title
    }
    meta.title = title
    router.meta = meta
    return router
}

export const showTitle = (item, vm) => {
    let { title, __titleIsFunction__ } = item.meta
    if (!title) return
    title = (item.meta && item.meta.title) || item.name
    return title
}

/**
 * 路由鉴权
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
    if (route.meta && route.meta.access) return hasOneOf(access, route.meta.access)
    else return true
  }

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
    const routePermissionJudge = (list) => {
        return list.some(item => {
            if (item.children && item.children.length) {
                return routePermissionJudge(item.children)
            } else if (item.name === name) {
                return hasAccess(access, item)
            }
        });
    }
    return routePermissionJudge(routes);
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
    return targetarr.some(_ => arr.indexOf(_) > -1);
}

/**
 * 
 * @param {*} arr1 
 * @param {*} arr2 
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
    return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * 传入一个毫秒数，转换为格式化的时分秒
 * 
 * @param {*} ms 
 * @returns 
 */
export const convertMs2Date = (ms) => {
    if (ms < 1000) {
        return '00:00:00.' + ms;
    }

    let sec = Math.floor(ms / 1000);
    let min = Math.floor(sec / 60);
    let hours = Math.floor(min / 60);

    sec %= 60;
    min %= 60;

    const formatTime = padZero(hours) + ":" + padZero(min) + ":" + padZero(sec);
    return formatTime;
}

/**
 * 数字不足两位时，在前面补0
 * 
 * @param {*} value 
 * @returns 
 */
export const padZero = (value) => {
    return String(value).padStart(2, '0');
}