import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './styles/index.less'

import {vue3ScrollSeamless} from "vue3-scroll-seamless";

import VueBMap, {initBMapApiLoader} from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css';
import VueMapvgl from 'vue-mapvgl'
initBMapApiLoader({
    ak: 'H82I8RyFsYNnMwQWDK7UMM7tfFEio6s0'
});

try {
    createApp(App)
    .use(router)
    .use(ViewUIPlus)
    .use(VueBMap)
    .use(VueMapvgl)
    .component('vue3ScrollSeamless',vue3ScrollSeamless)
    .mount('#app');
} catch (e) {
    console.log(e)
}
